import React from "react";

const Navbar2 = () => {
  return (
    <div className="navbar2">
        <h2 className="motd2">Welcome To Shotty Gaming!</h2>
        <pre className="motd">Skyblock     Survival     Bedwars     The Bridge     Build Battle     Murder Mystery</pre>
    </div>
  );
};

export default Navbar2;
