import React from 'react'

const Footer = () => {
    return (
        <div className="footer">
            Site Developed by Darklordbazz
        </div>
    )
}

export default Footer
